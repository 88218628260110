:root {
  --blue-color: #6cb9ff;
  --dark-blue-color: #203950;
  --homepage-border-radius: 6px;
  --dashboard-border-radius: 25px;
  --gray-border-color: #b5b5b5;
  --dashboard-center-column-width: 700px;
}

#root {
  min-width: 1023px; /** not responsive */
}
.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

main {
  min-width: 375px;
}
.App-headline {
  color: #ffffff;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 3.5em;
  margin-left: 10vh;
  width: 800px;
  margin: 50px auto;
}

article {
  display: flex;
  align-content: center;
  flex-direction: column;
}
article.homepage__first-section {
  background: var(--blue-color);
  height: 60vh;
  min-height: 405px;
  color: white;
}
article.homepage__second-section {
  height: 90vh;
  padding: 30px 20px;
  min-height: 605px;
}
article.homepage__second-section img {
  width: 100vh;
}
article.dashboard {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 1em 2em;
}
h2.all-contacts {
  text-align: left;
  font-weight: normal;
}
.dashboard__left-column {
  display: flex;
  flex-direction: column;
  width: 300px;
  text-align: left;
  margin-right: 20px;
}
.dashboard__left-column h2 {
  font-weight: normal;
}
.dashboard__left-column-group-list {
  padding: 5px;
  margin: 0;
}
.TeamsList__team {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dashboard__center-column {
  display: flex;
  flex-direction: column;
}
.circle-graph-image {
  width: 100vh;
}
.dashboard__container {
  background: #f5f5f5;
  border: 1px solid var(--gray-border-color);
  min-height: 0px;
}
.dashboard__search-filter-container {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  padding: 20px;
  width: 700px;
  white-space: nowrap;
}
.dashboard__search-bar-container {
  position: relative;
  border: none;
}
.dashboard__search-bar {
  width: 400px;
}
.TeamsList__team-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 135px;
}
.TeamMembersList {
  background-color: aliceblue;
  list-style: circle;
  padding-top: 10px;
  padding-bottom: 10px;
}

.magnifying-glass-icon {
  position: absolute;
  left: 20px;
  height: 25px;
  top: 8px;
}

section.homepage__first-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

section * {
  box-sizing: border-box;
}

section.homepage__first-section p {
  align-self: center;
  text-align: left;
  font-size: 20px;
  width: 300px;
  margin-right: 95px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 1.25em;
}

section.homepage__first-section form {
  align-self: center;
  justify-content: center;
  background: white;
  white-space: nowrap;
  padding: 15px;
  border-radius: var(--homepage-border-radius);
  padding: 10px 15px;
}

section.homepage__first-section * {
  border-radius: var(--homepage-border-radius);
  box-sizing: border-box;
}

section.homepage__second-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
section.search {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}
section.search > * {
  padding-right: 0.5em;
}
* {
  box-sizing: border-box;
}
input {
  border: none;
}
select#filters {
  font-size: 1em;
  background-color: #fff;
  margin-left: 10px;
}
input[name="search"] {
  padding-left: 36px;
  height: 30px;
  font-size: 1em;
  border-radius: var(--dashboard-border-radius);
}
.dashboard__search-bar-container input[name="search"] {
  padding-left: 47px;
}
input[name="search"]:hover,
input[name="search"]:focus {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
}
.visualize-header-container {
  display: flex;
  flex-direction: row;
  width: var(--dashboard-center-column-width);
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}
.visualize-header-container h2 {
  border: 2px solid var(--blue-color);
  padding: 1em;
  width: calc(var(--dashboard-center-column-width) / 3);
  height: 78px;
  z-index: 1;
  transition: all 0.5s ease;
  color: var(--blue-color);
}
.visualize-header-container h2.left {
  border-radius: var(--dashboard-border-radius) 0 0
    var(--dashboard-border-radius);
}
.visualize-header-container h2.right {
  border-radius: 0 var(--dashboard-border-radius) var(--dashboard-border-radius)
    0;
}
.visualize-header-container h2.is-selected {
  background: none;
  color: #fff;
}
.visualize-header-container span {
  background: var(--blue-color);
  position: absolute;
  top: 21px;
  width: calc(var(--dashboard-center-column-width) / 3);
  height: 76px;
  transition: 0.3s ease-out all;
}

.visualize-header-container span.left {
  left: 0px;
  border-radius: var(--dashboard-border-radius) 0 0
    var(--dashboard-border-radius);
}

.visualize-header-container span.middle {
  left: calc(var(--dashboard-center-column-width) / 3);
}

.visualize-header-container span.right {
  left: calc(2*var(--dashboard-center-column-width) / 3);
  border-radius: 0 var(--dashboard-border-radius) var(--dashboard-border-radius)
    0;
}
button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
form.profile {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 50px;
}
.profile__photo-upload__fieldset {
  grid-column: 2;
  justify-self: end;
  padding-right: 20px;
}
.profile__photo-upload__fieldset img {
  margin-top: 10px;
}
.profile__text-fields__fieldset {
  grid-column: 3;
  justify-self: start;
}
.profile__text-fields__fieldset > * {
  width: 300px;
}
article.policy {
  text-align: left;
  padding: 10px 40px;
  min-height: 65vh;
}
article.policy ul {
  list-style: initial;
}
h1.profile {
  font-size: 2em;
  grid-column: 2/4;
}
form.profile > button {
  grid-column: 2/4;
}
form.profile fieldset > * {
  margin-bottom: 10px;
}
form.profile fieldset {
  text-align: left;
  border: none;
  display: flex;
  flex-direction: column;
}
.profile-photo-upload {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.profile input[type="text"],
.homepage__first-section input[type="email"] {
  font-size: 1.25em;
  background: #e9e8e8;
  padding-left: 10px;
  height: 55px;
  margin-right: 10px;
}

textarea {
  resize: none;
  font-size: 1.25em;
  font-family: inherit;
}
button {
  cursor: pointer;
}
article.profile {
  padding: 1em;
}
.signup-homepage-first-section__button {
  display: inline-block;
  margin: 0;
  text-decoration: none;
  background: rgba(247, 147, 28, 0.76);
  color: #000;
  font-family: sans-serif;
  font-size: 1.5em;
  text-align: center;
  height: 55px;
  padding: 0 22px;
}
.profile-submit__link {
  grid-column: 2/4;
}
.profile-submit__button {
  display: inline-block;
  margin: 0;
  text-decoration: none;
  background: rgba(247, 147, 28, 0.76);
  color: #fff;
  font-family: sans-serif;
  font-size: 1.5em;
  text-align: center;
  height: 55px;
  padding: 0 22px;
  white-space: nowrap;
  justify-self: center;
  border-radius: var(--homepage-border-radius);
  margin: 20px;
}
/** Header */
.App-header {
  color: #fff;
  background: var(--blue-color);
  grid-template-columns: repeat(12, 1fr);
  padding: 0.5em 1.5em;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 15vh;
}
.App-header.dashboard {
  height: 10vh;
}
header {
  height: 15vh;
}

.App-logo {
  height: 75px;
  display: flex;
  align-self: center;
}
.App-logo.dashboard {
  height: 55px;
}
button {
  border-width: 0px;
}
ul {
  list-style: none;
}
h2.homepage__second-section {
  font-size: 2em;
}
ul.homepage__second-section {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
}
ul.homepage__second-section button {
  -webkit-appearance: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 24px;
  width: 177px;
  height: 112px;
  outline: none;
  background-color: #efefef;
}
ul.homepage__second-section button.is-selected {
  background: var(--blue-color);
}
nav,
footer ul {
  display: flex;
  justify-content: center;
  align-self: center;
}
footer > ul {
  align-items: center;
}
nav li {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5em;
  font-size: 1.5em;
  white-space: nowrap;
  font-weight: bold;
  pointer-events: visible;
}
nav li:hover {
  cursor: pointer;
}

nav a {
  text-decoration: none;
  color: #fff;
}
nav a:hover {
  color: #eee;
}
nav a.login {
  color: var(--blue-color);
  background: #fff;
  border-radius: 4px;
  padding: 20px 30px;
}
a.login:hover {
  background: #eee;
}
.sync-status-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0;
  padding: 10px;
}

.sync-status-item {
  background-color: #fff;
  border: 1px solid var(--gray-border-color);
  margin-bottom: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 55px;
  justify-content: space-between;
}
.show-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #898989;
  font-size: 0.75em;
  margin-bottom: 25px;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #898;
  margin: 0 5px;
}
button,
input {
  outline: none;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #898;
  margin: 0 5px;
}
.sync-status-item-email {
  display: flex;
  justify-content: space-between;
  width: 250px;
  color: var(--blue-color);
  align-items: center;
}
.sync-status-item-email p {
  padding: 0;
  margin: 0;
}
.contact-list__profile-image {
  border-radius: 50%;
  margin-right: 10px;
}
.contact-list__item {
  padding: 10px;
  border-bottom: 1px solid var(--gray-border-color);
  display: flex;
  align-items: center;
}
.contact-list__item-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.profile-attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-top: 3px;
}
.profile-name {
  margin: 0;
}
.profile-attribute-tags {
  padding: 0;
  margin: 0;
  margin-top: 3px;
}
/** Footer */
footer {
  background: #f4f6f7;
  color: #000;
  height: 20vh; /** main has 90vh, so 90vh + 10vh from footer = 100vh, full height of viewport */
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}
footer li {
  padding-right: 27px;
  font-size: 0.9em;
}
footer > ul a {
  color: black;
  font-size: 1.2em;
}
#footer-icatalyst-logo {
  margin-left: -23px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Dropdown Button */
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: -24px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  color: var(--blue-color);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

/* Graph Header button switch */
.GraphHeader__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.GraphHeader__items > li {
  margin-right: 10px;
}
.GraphHeader__items button {
  -webkit-appearance: none;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 19px;
  width: 177px;
  height: 50px;
  outline: none;
  color: var(--dark-blue-color);
}
.GraphHeader__items button.is-selected {
  background: var(--blue-color);
  color: white;
  font-weight: bold;
}
.GraphHeader__items button.is-selected:hover {
  background: var(--blue-color);
  color: white;
  font-weight: bold;
}

.GraphHeader__items button:hover {
  background: linear-gradient(90deg, #ffa50052, #ffa500a1);
  box-shadow: 0px 1px 3px 0px #ffa5002e;
}

#chart {
  width: 1300px;
}
