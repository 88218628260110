body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.control {
  float: left;
  padding-left: 20px;
}

.control a {
  color: black;
  font-size: 12px;
  padding: 5px;
  background: #E2E2E2;
  cursor: pointer;
  margin-right: 3px;
  text-decoration: none;
}

.control a:hover {
  text-decoration: underline;
}

.control a.active {
  color: white;
  background: #555;
}
